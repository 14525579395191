<template>
  <div>
    <el-card class="md" style="animation: article 1s;">
      <h2>{{"配一段登场BGM (ﾉ)`ω´(ヾ)"}}</h2>
      <meting-js :id="aboutInfo.musicId" server="netease" type="song" v-if="aboutInfo.musicId !==''"></meting-js>
      <div v-html="aboutInfo.content"></div>
    </el-card>
    <el-card id="comment" style="animation: article 1s;margin-top: 40px">
      <CommentList/>
    </el-card>
  </div>

</template>

<script>
import CommentList from "@/components/comment/CommentList";

export default {
  name: "About",
  components: {CommentList},
  data() {
    return {
      aboutInfo: {
        commentEnabled: '',
        content: '',
        musicId: ''
      }
    }
  },
  created() {
    this.getAboutInfo()
  },
  methods: {
    getAboutInfo() {
      this.$request.get("/about").then(res => {
        this.aboutInfo = res.data
      })
    }
  }
}
</script>

<style scoped>
/deep/ #comment .el-card__body {
  padding: 40px 50px;
}

/deep/ .el-card__body {
  padding: 40px 50px;
}
</style>